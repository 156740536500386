import Swal from "sweetalert2";

import moment from "moment";
import { MenuComponent } from "@/assets/ts/components";
import store from "@/store";
import i18n from "@/core/plugins/i18n";
import { CommonData } from "@/assets/ts/_utils";
import { Popover, Modal } from "bootstrap";
import venobox from "venobox";

import $ from "jquery";
import router from "@/router";
import * as keenthemes from "@/core/plugins/keenthemes";
import { createToaster } from "@meforma/vue-toaster";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import axios from "axios";
import { JsonData } from "../formdata/JsonData";

const toaster = createToaster({ position: "top" });

const { t, te } = i18n.global;
export const timeFormat = "HH:mm:ss";
export const viewTimeFormat = "hh:mm A";
export const DATE_FORMAT = "YYYY-MM-DD";

export const SOCIAL_MEDIA = {
	TELEPHONE: 0,
	WHATSAPP: 1,
	GOOGLE_MAPS: 2,
	INSTAGRAM: 3,
	SNAPCHAT: 4,
	FACEBOOK: 5,
	FACEBOOK_PAGE_ID: 6,
	TIKTOK: 7,
	GOOGLE_REVIEW: 8,
};

const hhmmssTimeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
const hhmmTimeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
const hhmmaTimeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (am|pm|Am|Pm|AM|PM)$/;

export function translate(text, params: any = null) {
	if (text == undefined || text == null) {
		return null;
	} else if (text === "") {
		return "";
	} else if (te(text)) {
		return t(text, params);
	} else {
		return text;
	}
}

export function translateNationality(text) {
	const result = getNationalityList().find((nationality) => nationality.english_name == text) as any;

	if (result != null) {
		return getValueByCurrentLanguage(result.english_name, result.arabic_name);
	}

	return text;
}

export function getCurrentLanguage() {
	return i18n.global.locale.value;
}

export function getValueByCurrentLanguage(englishValue, arabicValue) {
	if (i18n.global.locale.value == "en") {
		return englishValue;
	} else if (i18n.global.locale.value == "ar") {
		return arabicValue;
	}
	return null;
}

export function showCustomLoading() {
	store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
}

export function closeCustomLoading() {
	store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
}

export function showSwalLoading() {
	Swal.fire({
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		showConfirmButton: false,
		willOpen: () => {
			Swal.showLoading();

			jquery(".swal2-actions.swal2-loading").html(
				"<div class='p-5 text-center swal-logo-loading'><img alt='Logo' class='mh-50px' src='/media/logos/logo-1_" +
					getCurrentLanguage() +
					".svg' />" +
					"<div class='mt-4 dot-elastic mx-auto'></div></div>"
			);
		},
	} as any);
}

export function showInternetDisconnectLoading() {
	Swal.fire({
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		showConfirmButton: false,
		willOpen: () => {
			Swal.showLoading();

			jquery(".swal2-actions.swal2-loading").html(
				"<div class='p-5 text-center swal-logo-loading'><img alt='Logo' class='mh-50px' src='/media/logos/logo-1_" +
					getCurrentLanguage() +
					".svg' />" +
					"<div class='mt-4 dot-elastic mx-auto'></div><div class='mt-4 mx-auto fs-5 fw-bolder'>" +
					translate("no_internet_connection") +
					"<br/>" +
					translate("please_check_and_try_again") +
					"</div></div>"
			);
		},
	} as any);
}

export function isSwalShown() {
	return Swal.isVisible();
}

export function isSwalLoading() {
	return isSwalShown() && Swal.isLoading();
}

export function closeSwal() {
	Swal.close();
}

export function showDialog(title) {
	Swal.fire({
		title: translate(title),
		confirmButtonText: translate("ok"),
	});
}

export function showSuccessDialog(title) {
	Swal.fire({
		icon: "success",
		title: translate(title),
		confirmButtonText: translate("ok"),
	});
}

export function showSuccessDialogWithAction(title, action) {
	Swal.fire({
		icon: "success",
		title: translate(title),
		confirmButtonText: translate("ok"),
	}).then(() => {
		action();
	});
}

export function showErrorDialog(title) {
	Swal.fire({
		icon: "error",
		title: translate(title),
		confirmButtonText: translate("ok"),
	});
}

export function showWarningDialog(title, confirmButtonText = "ok") {
	return Swal.fire({
		icon: "warning",
		title: translate(title),
		confirmButtonText: translate(confirmButtonText),
	});
}

export function showRequestError(error) {
	if (error != null && error.response != null && error.response.status == 401) {
		goTo({ name: "merchant-sign-in" });
	} else if (error != null && error.response != null && error.response.status == 501) {
		if (error.response.data != "ignore") {
			if (error.response.data == "redirect_to_not_found") {
				goTo({ name: "404" });
			} else {
				Swal.fire({
					icon: "error",
					title: translate(error.response.data),
					confirmButtonText: translate("ok"),
				});
			}
		}
	} else {
		Swal.fire({
			icon: "error",
			title: translate("sorry_there_is_an_error_happened"),
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
		}).then(() => {
			refreshPage();
		});

		const jsonData = new JsonData();

		const errorJson = {
			error: error,
			errorString: error.toString(),
		};

		if (error.stack) {
			errorJson["stack"] = error.stack;
		}

		if (error.response) {
			errorJson["response"] = error.response;
		}

		if (error.request) {
			errorJson["request"] = error.request;
		}

		jsonData.append("type", isManager() ? 2 : 1);

		jsonData.append("error", errorJson);

		jsonData.append("route", router.currentRoute.value.name?.toString());

		jsonData.append("path", window.location.href);

		jsonData.append("dateAndTime", getCurrentTimestamp());

		jsonData.append("userAgent", navigator.userAgent);

		axios.post("js_errors/add_js_error", jsonData.getData());
	}
}

export function removeNullAndEmptyValues(values) {
	Object.keys(values).forEach((key) => {
		if (values[key] === null || values[key] == "") {
			delete values[key];
		}
	});

	return values;
}

export function getCheckBoxValue(value) {
	if (value == undefined || value == null) {
		return 0;
	}

	return value ? 1 : 0;
}

export function resetFormErrorMessage(form: any) {
	setTimeout(function () {
		form.setErrors({});
	}, 50);
}

export function disablePreviousDays(time: Date) {
	return moment(time).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD");
}

export function disablePreviousAndTodayDays(time: Date) {
	return moment(time).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD");
}

export function disableIncomingDays(time: Date) {
	return moment(time).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD");
}

export function dateToYYYYMMDD(date: Date) {
	return moment(date).format("YYYY-MM-DD");
}

export function YYYYMMDDToDate(date: string) {
	return moment(date, "YYYY-MM-DD").toDate();
}

export function roundNumber(number) {
	return parseFloat((Math.round(parseFloat(number) * 100) / 100).toFixed(2));
}

export function momentObject() {
	return moment();
}

export function roundNumberAsString(number) {
	if (!valueExist(number)) {
		return parseFloat("0").toFixed(2);
	}
	return (Math.round(parseFloat(number) * 100) / 100).toFixed(2);
}

export function getValueByIdFromArray(id, options, attribute = "id") {
	if (id == undefined || id == null) {
		return null;
	}

	let newValue = null;
	for (let i = 0; i < options.length; i++) {
		if (id == options[i][attribute]) {
			newValue = options[i];
		}
	}
	return newValue;
}

export function getValuesByIdsFromArray(ids: Array<number>, options: any) {
	const newValues: any = [];
	for (let i = 0; i < ids.length; i++) {
		for (let j = 0; j < options.length; j++) {
			if (ids[i] == options[j].id) {
				newValues.push(options[j]);
			}
		}
	}
	return newValues;
}

export function translateArray(array, key = "id") {
	if (!valueExist(array)) {
		return null;
	}

	const result = [] as Array<any>;
	for (let i = 0; i < array.length; i++) {
		result.push(translate(array[i][key]));
	}
	return result.join(comma() + " ");
}

export function safeParseInt(value) {
	if (value != null) {
		return parseInt(value);
	}
	return value;
}

export function moveElement(element, to, prepend = false) {
	if (prepend) {
		to?.prepend(element);
	} else {
		to?.appendChild(element);
	}
}

export function moveElementByClassToId(fromClass, toId) {
	document.getElementById(toId)?.appendChild(document.getElementsByClassName(fromClass)[0]);
}

export function generateRandomKey() {
	return Date.now() + Math.random();
}

export function getRelativeUrl(url) {
	return process.env.VUE_APP_BASE_PATH + url;
}

export function reinitializeComponents() {
	keenthemes.reinitializeSpecificComponents(["menu", "popover", "tooltip", "progressbar"]);

	fixModalPoppers();

	fixTextOfElSwitch();
}

export function hideDropDownMenus() {
	MenuComponent.hideDropdowns(undefined);
}

export function getCurrentTimestamp() {
	return moment().format("YYYY-MM-DD HH:mm:ss");
}

export function getDateFromNow(operation: string | null = null, period: any = 0, periodType: any = "days") {
	const date = moment();

	if (operation != null) {
		if (operation == "add") {
			date.add(period, periodType);
		} else if (operation == "subtract") {
			date.subtract(period, periodType);
		}
	}

	return momentToString(date);
}

export function getDateFromMonth(date, operation) {
	if (operation == "start") {
		return momentToString(moment(date).startOf("month"));
	} else if (operation == "end") {
		return momentToString(moment(date).endOf("month"));
	}

	return date;
}

export function getDateFromYear(date, operation) {
	if (operation == "start") {
		return momentToString(moment(date).startOf("year"));
	} else if (operation == "end") {
		return momentToString(moment(date).endOf("month"));
	}

	return date;
}

export function getTimeFromNow(operation: string | null = null, period: any = 0, periodType: any = "days") {
	const date = moment();

	if (operation != null) {
		if (operation == "add") {
			date.add(period, periodType);
		} else if (operation == "subtract") {
			date.subtract(period, periodType);
		}
	}

	return momentToString(date, timeFormat);
}

export function momentToString(date, format = "YYYY-MM-DD") {
	return date.format(format);
}

export function getGlobalData() {
	if (store.getters.currentGlobalInformations != null) {
		return store.getters.currentGlobalInformations;
	}
	return {};
}

export function getAccountData() {
	if (store.getters.currentGlobalInformations != null) {
		return store.getters.currentGlobalInformations["account"];
	}
	return {};
}

export function getUserData() {
	if (store.getters.currentGlobalInformations != null) {
		return store.getters.currentGlobalInformations["user"];
	}
	return {};
}

export function getGeneralData() {
	if (store.getters.currentGlobalInformations != null) {
		return store.getters.currentGlobalInformations["general_informations"];
	}
	return {};
}

export function downloadFileFromResponse(response) {
	const headerLine = response.headers["content-disposition"];
	const startFileNameIndex = headerLine.indexOf('"') + 1;
	const endFileNameIndex = headerLine.lastIndexOf('"');
	const fileName = headerLine.substring(startFileNameIndex, endFileNameIndex);

	const url = window.URL.createObjectURL(new Blob([response.data]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();
	link.remove();

	showSuccessDialog(fileName + " downloaded successfully");
}

export function getFileNameFromUrl(url) {
	const urlSegments = url.split("/");
	return urlSegments[urlSegments.length - 1];
}

export function downloadFile(url, name = null as any) {
	const a = document.createElement("a");
	a.href = url;
	if (name == null) {
		a.download = getFileNameFromUrl(url);
	} else {
		a.download = name;
	}
	a.click();
	a.remove();
}

export function formatId(prefix, id) {
	if (id < 10) {
		return prefix + "0" + id;
	}
	return prefix + id;
}

export function formatDateAndTime(dateAndTime, format = "YYYY-MM-DD HH:mm:ss") {
	return moment(dateAndTime).format(format);
}

export function isNull(value, allowEmpty = true) {
	if (allowEmpty) {
		return value == undefined || value == null;
	} else {
		return value == undefined || value == null || value == "";
	}
}

export function isNotNull(value, allowEmpty = true) {
	if (allowEmpty) {
		return value != undefined && value != null;
	} else {
		return value != undefined && value != null && value != "";
	}
}

export function parseRouteParamToInt(value) {
	if (!valueExist(value)) {
		return null;
	}

	return parseInt(value);
}

export function replaceAll(str, find, replace) {
	if (str == null) {
		return "";
	}
	return str.replace(new RegExp(find, "g"), replace);
}

export function getNationalityList() {
	return [
		{ english_name: "Algeria", arabic_name: "الجزائر" },
		{ english_name: "Argentina", arabic_name: "الارجنتين" },
		{ english_name: "Australia", arabic_name: "استراليا" },
		{ english_name: "Austria", arabic_name: "النمسا" },
		{ english_name: "Azerbaijan", arabic_name: "اذربيجان" },
		{ english_name: "Bahrain", arabic_name: "البحرين" },
		{ english_name: "Belgium", arabic_name: "بلجيكا" },
		{ english_name: "Bosnia and Herzegovina", arabic_name: "البوسنة والهرسك" },
		{ english_name: "Brazil", arabic_name: "البرازيل" },
		{ english_name: "Brunei Darussalam", arabic_name: "بروناي دار السلام" },
		{ english_name: "Bulgaria", arabic_name: "بلغاريا" },
		{ english_name: "Cameroon", arabic_name: "الكاميرون" },
		{ english_name: "Canada", arabic_name: "كندا" },
		{ english_name: "China", arabic_name: "الصين" },
		{ english_name: "Colombia", arabic_name: "كولومبيا" },
		{ english_name: "Comoros", arabic_name: "جزر القمر" },
		{ english_name: "Costa Rica", arabic_name: "كوستا ريكا" },
		{ english_name: "Croatia", arabic_name: "كرواتيا" },
		{ english_name: "Cuba", arabic_name: "كوبا" },
		{ english_name: "Cyprus", arabic_name: "قبرص" },
		{ english_name: "Czechia", arabic_name: "التشيك" },
		{ english_name: "Denmark", arabic_name: "الدنمارك" },
		{ english_name: "Djibouti", arabic_name: "جيبوتي" },
		{ english_name: "Ecuador", arabic_name: "اكوادور" },
		{ english_name: "Egypt", arabic_name: "مصر" },
		{ english_name: "Ethiopia", arabic_name: "اثيوبيا" },
		{ english_name: "Finland", arabic_name: "فنلندا" },
		{ english_name: "France", arabic_name: "فرنسا" },
		{ english_name: "Georgia", arabic_name: "جورجيا" },
		{ english_name: "Germany", arabic_name: "المانيا" },
		{ english_name: "Ghana", arabic_name: "غانا" },
		{ english_name: "Greece", arabic_name: "اليونان" },
		{ english_name: "Hong Kong", arabic_name: "هونغ كونغ" },
		{ english_name: "Hungary", arabic_name: "هنغاريا" },
		{ english_name: "Iceland", arabic_name: "ايسلندا" },
		{ english_name: "India", arabic_name: "الهند" },
		{ english_name: "Indonesia", arabic_name: "اندونيسيا" },
		{ english_name: "Iran", arabic_name: "ايران" },
		{ english_name: "Iraq", arabic_name: "العراق" },
		{ english_name: "Ireland", arabic_name: "ايرلندا" },
		{ english_name: "Italy", arabic_name: "ايطاليا" },
		{ english_name: "Japan", arabic_name: "اليابان" },
		{ english_name: "Jordan", arabic_name: "الاردن" },
		{ english_name: "Kazakhstan", arabic_name: "كازاخستان" },
		{ english_name: "Kenya", arabic_name: "كينيا" },
		{ english_name: "Korea", arabic_name: "كوريا" },
		{ english_name: "Kuwait", arabic_name: "الكويت" },
		{ english_name: "Kyrgyzstan", arabic_name: "قيرغيزستان" },
		{ english_name: "Latvia", arabic_name: "لاتفيا" },
		{ english_name: "Lebanon", arabic_name: "لبنان" },
		{ english_name: "Libya", arabic_name: "ليبيا" },
		{ english_name: "Malaysia", arabic_name: "ماليزيا" },
		{ english_name: "Mali", arabic_name: "مالي" },
		{ english_name: "Malta", arabic_name: "مالطا" },
		{ english_name: "Mauritania", arabic_name: "موريتانيا" },
		{ english_name: "Mexico", arabic_name: "المكسيك" },
		{ english_name: "Monaco", arabic_name: "موناكو" },
		{ english_name: "Mongolia", arabic_name: "منغوليا" },
		{ english_name: "Montenegro", arabic_name: "الجبل الاسود" },
		{ english_name: "Morocco", arabic_name: "المغرب" },
		{ english_name: "Mozambique", arabic_name: "موزامبيق" },
		{ english_name: "Myanmar", arabic_name: "ميانمار" },
		{ english_name: "Nepal", arabic_name: "نيبال" },
		{ english_name: "Netherlands", arabic_name: "هولندا" },
		{ english_name: "New Zealand", arabic_name: "نيوزيلندا" },
		{ english_name: "Niger", arabic_name: "النيجر" },
		{ english_name: "Nigeria", arabic_name: "نيجيريا" },
		{ english_name: "Norway", arabic_name: "النرويج" },
		{ english_name: "Oman", arabic_name: "سلطنة عمان" },
		{ english_name: "Pakistan", arabic_name: "باكستان" },
		{ english_name: "Palestine", arabic_name: "فلسطين" },
		{ english_name: "Panama", arabic_name: "بنما" },
		{ english_name: "Paraguay", arabic_name: "باراغواي" },
		{ english_name: "Peru", arabic_name: "بيرو" },
		{ english_name: "Philippines", arabic_name: "الفلبين" },
		{ english_name: "Poland", arabic_name: "بولندا" },
		{ english_name: "Portugal", arabic_name: "البرتغال" },
		{ english_name: "Qatar", arabic_name: "قطر" },
		{ english_name: "Romania", arabic_name: "رومانيا" },
		{ english_name: "Russia", arabic_name: "روسيا" },
		{ english_name: "Rwanda", arabic_name: "رواندا" },
		{ english_name: "Saudi Arabia", arabic_name: "السعودية" },
		{ english_name: "Senegal", arabic_name: "السنغال" },
		{ english_name: "Serbia", arabic_name: "صربيا" },
		{ english_name: "Singapore", arabic_name: "سنغافورة" },
		{ english_name: "Slovakia", arabic_name: "سلوفاكيا" },
		{ english_name: "Slovenia", arabic_name: "سلوفينيا" },
		{ english_name: "Somalia", arabic_name: "الصومال" },
		{ english_name: "South Africa", arabic_name: "جنوب افريقيا" },
		{ english_name: "Spain", arabic_name: "اسبانيا" },
		{ english_name: "Sri Lanka", arabic_name: "سيريلانكا" },
		{ english_name: "Sudan", arabic_name: "السودان" },
		{ english_name: "Sweden", arabic_name: "السويد" },
		{ english_name: "Switzerland", arabic_name: "سويسرا" },
		{ english_name: "Syria", arabic_name: "سوريا" },
		{ english_name: "Taiwan", arabic_name: "تايوان" },
		{ english_name: "Tajikistan", arabic_name: "طاجيكستان" },
		{ english_name: "Thailand", arabic_name: "تايلاند" },
		{ english_name: "Tunisia", arabic_name: "تونس" },
		{ english_name: "Turkey", arabic_name: "تركيا" },
		{ english_name: "Turkmenistan", arabic_name: "تركمانستان" },
		{ english_name: "Uganda", arabic_name: "اوغندا" },
		{ english_name: "Ukraine", arabic_name: "اوكرانيا" },
		{ english_name: "United Arab Emirates", arabic_name: "الامارات العربية المتحدة" },
		{ english_name: "United States of America", arabic_name: "الولايات المتحدة الامريكية" },
		{ english_name: "Uruguay", arabic_name: "اوروغواي" },
		{ english_name: "Uzbekistan", arabic_name: "اوزبكستان" },
		{ english_name: "British", arabic_name: "بريطانيا" },
		{ english_name: "Yemen", arabic_name: "اليمن" },
		{ english_name: "Zambia", arabic_name: "زامبيا" },
		{ english_name: "Zimbabwe", arabic_name: "زمبابوي" },
	];
}

export function getElementTopOffset(element) {
	const box = element.getBoundingClientRect();

	const body = document.body;
	const docEl = document.documentElement;

	const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

	const clientTop = docEl.clientTop || body.clientTop || 0;

	const top = box.top + scrollTop - clientTop;

	return top;
}

export function safeSplit(value, splitCharacter = ",") {
	if (value == undefined || value == null) {
		return [];
	}

	return value.split(splitCharacter);
}

export function safeSplitIds(value, splitCharacter = ",") {
	if (value == undefined || value == null) {
		return [];
	}

	const result = value.split(splitCharacter);

	for (let i = 0; i < result.length; i++) {
		result[i] = parseInt(result[i]);
	}

	return result;
}

export function numberSafe(value) {
	if (value == undefined || value == null) {
		return 0;
	}

	return value;
}

export function safeValue(value, defaultValue: any = null) {
	if (value == undefined || value == null) {
		return defaultValue;
	}

	return value;
}

export function safeAttribute(value, key, defaultValue: any = null) {
	if (!valueExist(value) || !valueExist(value[key])) {
		return defaultValue;
	}

	return value[key];
}

export function safeAppendValue(value, newValue, defaultValue = null) {
	if (value == undefined || value == null) {
		return defaultValue;
	}

	return newValue;
}

export function valueExist(value, nonEmpty = false) {
	return value != undefined && value != null && (!nonEmpty || (nonEmpty && value != ""));
}

export function getServerMediaPath(url) {
	if (url == undefined || url == null) {
		return null;
	}

	return process.env.VUE_APP_URL + "/storage/" + url;
}

export function getGenderList() {
	return [
		{ id: 1, name: translate("male") },
		{ id: 2, name: translate("female") },
		{ id: 3, name: translate("business") },
		{ id: 4, name: translate("not_specific") },
	];
}

export function translateGender(id) {
	const genderObject: any = getValueByIdFromArray(id, getGenderList());
	if (valueExist(genderObject)) {
		return genderObject.name;
	}
	return null;
}

export function translateDay(id) {
	if (id == 0) {
		return translate("sunday");
	} else if (id == 1) {
		return translate("monday");
	} else if (id == 2) {
		return translate("tuesday");
	} else if (id == 3) {
		return translate("wednesday");
	} else if (id == 4) {
		return translate("thursday");
	} else if (id == 5) {
		return translate("friday");
	} else if (id == 6) {
		return translate("saturday");
	}

	return null;
}

export function fixModalPoppers() {
	jquery(".modal-popper:not([moved-to-modal-body])").each(function (index, element) {
		const baseElement = jquery("input.el-input__inner[aria-controls='" + jquery(element).attr("id") + "']");
		baseElement.attr("moved-to-modal-body", "true");

		baseElement.closest(".modal-body")?.append(element);
	});

	jquery(".modal-body .el-select__popper:not(.moved-to-modal-body)").each(function (index, element) {
		jquery(element).attr("moved-to-modal-body", "true");
		jquery(element).closest(".modal-body")?.append(element);
	});
}

export function fixTextOfElSwitch() {
	jquery(".el-switch[custom-active-text]:not([custom-active-text-done])").each(function (index, element) {
		jquery(element).find(".el-switch__core .el-switch__inner .is-text").eq(0).html(jquery(element).attr("custom-active-text"));
		jquery(element).attr("custom-active-text-done", "true");
	});

	jquery(".el-switch[custom-inactive-text]:not([custom-inactive-text-done])").each(function (index, element) {
		jquery(element).find(".el-switch__core .el-switch__inner .is-text").eq(1).html(jquery(element).attr("custom-inactive-text"));
		jquery(element).attr("custom-inactive-text-done", "true");
	});
}

export function getDayNumberFromDate(date) {
	return moment(date).weekday();
}

export function getCurrentDirection() {
	return document.querySelector("html")?.getAttribute("direction");
}

export function isAppointmentsConflict(from1, to1, from2, to2) {
	if ((from1 >= from2 && from1 < to2) || (to1 > from2 && to1 <= to2) || (from1 <= from2 && to1 >= to2)) {
		return true;
	}
	return false;
}

export function initBootstrapDateRangePicker(id, fromDate, toDate, customMethod) {
	initBootstrapDateRangePickerByElement(document.getElementById(id), fromDate, toDate, customMethod);
}

export function initBootstrapDateRangePickerByElement(element, fromDate, toDate, customMethod, extraOptions = {}) {
	$(element).daterangepicker(
		{
			startDate: moment(fromDate),
			endDate: moment(toDate),
			ranges: getDateRangerPickerRanges(),
			locale: {
				customRangeLabel: translate("custom_range"),
				applyLabel: translate("apply"),
				cancelLabel: translate("cancel"),
				format: "YYYY-MM-DD",
			},
			...extraOptions,
		},
		customMethod
	);
}

export function initBootstrapDatePicker(id, fromDate, customMethod, extraOptions = {}) {
	$("#" + id).daterangepicker(
		{
			singleDatePicker: true,
			autoApply: true,
			startDate: moment(fromDate),
			endDate: moment(fromDate),
			autoUpdateInput: true,
			locale: {
				format: "YYYY-MM-DD",
			},
			...extraOptions,
		},
		customMethod
	);
}

export function getDateRangerPickerRanges() {
	const ranges = {} as Array<any>;
	ranges[translate("today")] = [moment(), moment()];
	ranges[translate("yesterday")] = [moment().subtract(1, "days"), moment().subtract(1, "days")];
	ranges[translate("this_month")] = [moment().startOf("month"), moment().endOf("month")];
	ranges[translate("last_month")] = [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")];
	ranges[translate("last_quarter")] = [moment().subtract(1, "quarter").startOf("quarter"), moment().subtract(1, "quarter").endOf("quarter")];

	return ranges;
}

export function getLabelForBootstrapDateRangePicker(startDate, endDate) {
	const ranges = getDateRangerPickerRanges();

	for (const label of Object.keys(ranges)) {
		if (startDate == formatDate(ranges[label][0]) && endDate == formatDate(ranges[label][1])) {
			return label;
		}
	}

	return translate("custom_range");
}

export function setDateRangePickerDates(element, fromDate, toDate) {
	$(element).data("daterangepicker").setStartDate(moment(fromDate));
	$(element).data("daterangepicker").setEndDate(moment(toDate));
}

export function bootstrapDatePickerSetDate(id, date) {
	$("#" + id)
		.data("daterangepicker")
		.setStartDate(moment(date));
}

export const languages = {
	en: {
		name: "english",
		displayName: "English",
		dir: "ltr",
	},
	ar: {
		name: "arabic",
		displayName: "العربية",
		dir: "rtl",
	},
};

export function resolveUrlHref(route) {
	return router.resolve({ name: route.name, params: { ...route.params }, query: { ...route.query } }).href;
}

export function goTo(to) {
	router.push({
		...to,
		params: {
			...router.currentRoute.value.params,
			...to.params,
			language: router.currentRoute.value.params.language,
		},
	});
}

export function goToThenReload(to) {
	router
		.push({
			...to,
			params: {
				...router.currentRoute.value.params,
				...to.params,
				language: router.currentRoute.value.params.language,
			},
		})
		.then(() => {
			window.location.reload();
		});
}

export function toggleLanguage() {
	let newRoute = null as any;

	if (valueExist(router.currentRoute.value.params.language, true)) {
		if (router.currentRoute.value.params.language == "ar") {
			localStorage.setItem("lang", "en");
			newRoute = {
				...router.currentRoute.value,
				params: {
					...router.currentRoute.value.params,
					language: "en",
				},
			};
		} else if (router.currentRoute.value.params.language == "en") {
			localStorage.setItem("lang", "ar");
			newRoute = {
				...router.currentRoute.value,
				params: {
					...router.currentRoute.value.params,
					language: "",
				},
			};
		}
	} else {
		localStorage.setItem("lang", "en");
		newRoute = {
			...router.currentRoute.value,
			params: {
				...router.currentRoute.value.params,
				language: "en",
			},
		};
	}

	if (valueExist(store.getters.currentUser)) {
		setUserLastLanguage(isManager() ? "manager" : "user", store.getters.currentUser.id, true, localStorage.getItem("lang"));
	}

	router.push(newRoute).then(() => {
		window.location.reload();
	});
}

export function setLanguage(language) {
	let newRoute = null as any;

	localStorage.setItem("lang", language);
	newRoute = {
		...router.currentRoute.value,
		params: {
			...router.currentRoute.value.params,
			language: language,
		},
	};

	router.push(newRoute).then(() => {
		window.location.reload();
	});
}

export function formatDate(date, format = "YYYY-MM-DD") {
	return moment(date).format(format);
}

export function utc() {
	return moment().utc();
}

export function utcToLocal(dateAndTime) {
	return formatDateAndTime(moment.utc(dateAndTime).local());
}

export function formatTime(time, format = timeFormat) {
	if (hhmmssTimeRegex.test(time)) {
		return moment(time, "HH:mm:ss").format(format);
	} else if (hhmmTimeRegex.test(time)) {
		return moment(time, "HH:mm").format(format);
	} else if (hhmmaTimeRegex.test(time)) {
		return moment(time, "hh:mm A").format(format);
	}
	return moment(time).format(format);
}

export function formatViewTime(time) {
	return convertTimeToArabic(formatTime(time, viewTimeFormat));
}

export const badgeTypeMap = {
	appointmentStatus: {
		0: {
			title: "cancelled",
			backgroundColor: "danger",
		},
		1: {
			title: "scheduled",
			backgroundColor: "primary",
		},
		2: {
			title: "check_in",
			backgroundColor: "blue",
		},
	},
	appointmentPaymentStatus: {
		0: {
			title: "unpaid",
			backgroundColor: "primary",
		},
		1: {
			title: "paid",
			backgroundColor: "success",
		},
		2: {
			title: "waiting",
			backgroundColor: "warning",
		},
	},
	appointmentSource: {
		0: {
			title: "system",
			backgroundColor: "primary",
		},
		1: {
			title: "online",
			backgroundColor: "success",
		},
	},
	giftCardType: {
		0: {
			title: "paid_voucher",
			backgroundColor: "primary",
		},
		1: {
			title: "loyality_voucher",
			backgroundColor: "warning",
		},
		2: {
			title: "free_voucher",
			backgroundColor: "success",
		},
	},
	stockOperation: {
		0: {
			title: "add_quantity",
		},
		1: {
			title: "expired",
		},
		2: {
			title: "lost",
		},
		3: {
			title: "internal_use",
		},
		4: {
			title: "initial_quantity",
		},
		5: {
			title: "sold",
		},
		6: {
			title: "invoice_return",
		},
		billing: {
			title: "billing",
		},
	},
	invoiceItemEmployeeChanged: {
		0: {
			title: "",
		},
		1: {
			title: "changed",
			backgroundColor: "warning",
		},
	},
	giftCardStatus: {
		0: {
			title: "used",
			backgroundColor: "danger",
		},
		1: {
			title: "active",
			backgroundColor: "success",
		},
	},
	isWorkingDayStatus: {
		0: {
			title: "no",
			backgroundColor: "danger",
		},
		1: {
			title: "yes",
			backgroundColor: "success",
		},
	},
	status: {
		0: {
			title: "disabled",
			backgroundColor: "danger",
		},
		1: {
			title: "active",
			backgroundColor: "success",
		},
	},
	yesNo: {
		0: {
			title: "no",
			backgroundColor: "danger",
		},
		1: {
			title: "yes",
			backgroundColor: "success",
		},
	},
	paymentStatus: {
		0: {
			title: "cancelled",
			backgroundColor: "danger",
		},
		1: {
			title: "paid",
			backgroundColor: "success",
		},
	},
	managerPaymentStatus: {
		0: {
			title: "cancelled",
			backgroundColor: "danger",
		},
		1: {
			title: "not_paid",
			backgroundColor: "danger",
		},
		2: {
			title: "paid",
			backgroundColor: "success",
		},
	},
	showEmployeeInAppointments: {
		0: {
			title: "no",
			backgroundColor: "danger",
		},
		1: {
			title: "yes",
			backgroundColor: "success",
		},
	},
	showEmployeeInBilling: {
		0: {
			title: "no",
			backgroundColor: "danger",
		},
		1: {
			title: "yes",
			backgroundColor: "success",
		},
	},
	appointmentSent: {
		0: {
			title: "no",
			backgroundColor: "danger",
		},
		1: {
			title: "yes",
			backgroundColor: "success",
		},
	},
	sent: {
		0: {
			title: "no",
			backgroundColor: "danger",
		},
		1: {
			title: "yes",
			backgroundColor: "success",
		},
	},
	showToCustomer: {
		0: {
			title: "no",
			backgroundColor: "danger",
		},
		1: {
			title: "yes",
			backgroundColor: "success",
		},
	},
	loyalityAddAutomatically: {
		0: {
			title: "no",
			backgroundColor: "danger",
		},
		1: {
			title: "yes",
			backgroundColor: "success",
		},
	},
	offerUseServicesType: {
		1: {
			title: "fixed_services",
		},
		2: {
			title: "selection_from_customer",
		},
	},
	customerPenaltyType: {
		1: {
			title: "customer_no_show",
		},
		2: {
			title: "others",
		},
	},
	paymentSource: {
		0: {
			title: "billing",
		},
		1: {
			title: "deposit",
		},
		2: {
			title: "debit_note",
		},
		3: {
			title: "credit_note",
		},
	},
	whatsappConnected: {
		0: {
			title: "disconnected",
			backgroundColor: "danger",
		},
		1: {
			title: "connected",
			backgroundColor: "success",
		},
		2: {
			title: "server_disconnected",
			backgroundColor: "danger",
		},
	},
	whatsappPackageStatus: {
		0: {
			title: "disabled",
			backgroundColor: "danger",
		},
		1: {
			title: "active",
			backgroundColor: "success",
		},
		2: {
			title: "paused",
			backgroundColor: "warning",
		},
	},
	mainInstanceStatus: {
		0: {
			title: "disconnected",
			backgroundColor: "danger",
		},
		1: {
			title: "connected",
			backgroundColor: "success",
		},
		2: {
			title: "server_disconnected",
			backgroundColor: "warning",
		},
		3: {
			title: "paused",
			backgroundColor: "warning",
		},
	},
	campaignStatus: {
		0: {
			title: "disabled",
			backgroundColor: "danger",
		},
		1: {
			title: "active",
			backgroundColor: "primary",
		},
		2: {
			title: "sending",
			backgroundColor: "primary",
		},
		3: {
			title: "paused",
			backgroundColor: "warning",
		},
		4: {
			title: "temporary_saved",
			backgroundColor: "info",
		},
		5: {
			title: "completed",
			backgroundColor: "success",
		},
	},
	campaignCustomerSent: {
		0: {
			title: "failed",
			backgroundColor: "danger",
		},
		1: {
			title: "pending",
			backgroundColor: "primary",
		},
		2: {
			title: "sent",
			backgroundColor: "success",
		},
	},
	discountCodeCategory: {
		1: {
			title: "all_services_and_products",
		},
		2: {
			title: "all_services",
		},
		3: {
			title: "all_products",
		},
		4: {
			title: "selected_services_and_products",
		},
		5: {
			title: "selected_services",
		},
		6: {
			title: "selected_products",
		},
	},
	developmentSuggestionStatus: {
		0: {
			title: "Rejected",
			backgroundColor: "danger",
		},
		1: {
			title: "new",
			backgroundColor: "primary",
		},
		2: {
			title: "under_processing",
			backgroundColor: "warning",
		},
		3: {
			title: "done",
			backgroundColor: "success",
		},
	},
	accountRequestStatus: {
		0: {
			title: "Rejected",
			backgroundColor: "danger",
		},
		1: {
			title: "active",
			backgroundColor: "primary",
		},
		2: {
			title: "waiting for verify",
			backgroundColor: "blue",
		},
		3: {
			title: "approved",
			backgroundColor: "success",
		},
	},
	accountWhatsappPackageStatus: {
		0: {
			title: "Disabled",
			backgroundColor: "danger",
		},
		1: {
			title: "Active",
			backgroundColor: "primary",
		},
		2: {
			title: "Paused",
			backgroundColor: "warning",
		},
	},
	editProductRequest: {
		0: {
			title: "rejected",
			backgroundColor: "danger",
		},
		1: {
			title: "active",
			backgroundColor: "primary",
		},
		2: {
			title: "approved",
			backgroundColor: "success",
		},
	},
	jsErrorType: {
		1: {
			title: "User",
		},
		2: {
			title: "Manager",
		},
	},
	mailSentStatus: {
		0: {
			title: "failed",
			backgroundColor: "danger",
		},
		1: {
			title: "pending",
			backgroundColor: "warning",
		},
		2: {
			title: "success",
			backgroundColor: "success",
		},
	},
	failedLoginAttemptsType: {
		1: {
			title: "Email",
		},
		2: {
			title: "Password",
		},
		3: {
			title: "blocked",
		},
		4: {
			title: "disallowed_country",
		},
	},
	failedLoginAttemptsSource: {
		1: {
			title: "User",
		},
		2: {
			title: "Manager",
		},
	},
	managerImagesCheck: {
		1: {
			title: "account_logo",
		},
		2: {
			title: "employee_image",
		},
		3: {
			title: "gift_card_logo",
		},
		4: {
			title: "invoice_logo",
		},
		5: {
			title: "online_background",
		},
		6: {
			title: "offer_image",
		},
	},
	paymentGatewayStatus: {
		1: {
			title: "pending",
			backgroundColor: "warning",
		},
		2: {
			title: "pending",
			backgroundColor: "warning",
		},
		3: {
			title: "failed",
			backgroundColor: "danger",
		},
		4: {
			title: "failed",
			backgroundColor: "danger",
		},
		5: {
			title: "paid",
			backgroundColor: "success",
		},
		6: {
			title: "failed",
			backgroundColor: "danger",
		},
	},
	seoLinkType: {
		1: {
			title: "real",
		},
		2: {
			title: "dummy",
		},
	},
	redirectType: {
		1: {
			title: "enhancement",
		},
		2: {
			title: "google_console",
		},
	},
	expectedExpenseStatus: {
		1: {
			title: "pending",
			backgroundColor: "warning",
		},
		2: {
			title: "moved",
			backgroundColor: "success",
		},
	},
	zatcaSettingStatus: {
		0: {
			title: "cancelled",
			backgroundColor: "danger",
		},
		1: {
			title: "pending",
			backgroundColor: "warning",
		},
		2: {
			title: "connected",
			backgroundColor: "success",
		},
		3: {
			title: "renewed",
			backgroundColor: "success",
		},
	},
};

export function getTimesList(from = "00:00:00", to = "23:59:59", step = "15") {
	const result = [] as Array<any>;

	const fromMoment = moment(getDateFromNow() + " " + from);
	const toMoment = moment(getDateFromNow() + " " + to);

	while (compareDates(toMoment, fromMoment) >= 0) {
		result.push(fromMoment.format(timeFormat));

		fromMoment.add(step, "minutes");
	}

	return result;
}

export function arraySumAttribute(array, attribute, positiveNumber) {
	return array.reduce(function (a, b) {
		const firstValue = valueExist(a) ? parseFloat(positiveNumber ? Math.abs(a) : a) : 0;
		const secondValue = valueExist(b) && valueExist(b[attribute]) ? parseFloat(positiveNumber ? Math.abs(b[attribute]) : b[attribute]) : 0;

		return firstValue + secondValue;
	}, 0);
}

export function arraySumMethod(array, method) {
	return array.reduce(method, 0);
}

export const countryConstants = {
	KSA: 1,
};

export function getTlvForValue(tagNumber, tagValue) {
	return toHex(tagNumber) + toHex(tagValue.length) + tagValue;
}

export function toHex(value) {
	let hex = value.toString(16);
	if (hex.length % 2 > 0) {
		hex = "0" + hex;
	}
	hex = hex.toUpperCase();
	const input = hex.length % 2 == 0 ? hex : hex + "0";
	let output = "";
	for (let i = 0; i < input.length; i += 2) {
		const str = input.substring(i, i + 2);
		output += String.fromCharCode(parseInt(str, 16));
	}
	return output;
}

export function getFirstExistValue(...args) {
	for (let i = 0; i < args.length; i++) {
		if (valueExist(args[i])) {
			return args[i];
		}
	}
	return null;
}

export function safeSubString(text, start, length) {
	if (!valueExist(text)) {
		return "";
	}

	if (start == null || length == null) {
		return text;
	}

	return text.substring(start, length);
}

const variants = ["primary", "success", "danger", "info", "dark"];
export function getRandomVarient() {
	return variants[Math.floor(Math.random() * variants.length)];
}

export function isUserHasAccess(pageId, permissionId: any = null) {
	if (!valueExist(getUserData()["permissions"])) {
		return false;
	}

	if (!valueExist(permissionId)) {
		return valueExist(getUserData()["permissions"][pageId]);
	} else {
		return valueExist(getUserData()["permissions"][pageId]) && getUserData()["permissions"][pageId].includes(permissionId);
	}
}

export function isUserHasAccessPages(pages) {
	for (let i = 0; i < pages.length; i++) {
		if (isUserHasAccess(pages[i])) {
			return true;
		}
	}

	return false;
}

export function isHasAccessToPage(pageId) {
	if (!valueExist(getUserData()["permissions"])) {
		return false;
	}

	return valueExist(getUserData()["permissions"][pageId]);
}

export function validateUserHasAccessToPage(pageId, permissionId: any = null) {
	if (!isUserHasAccess(pageId, permissionId)) {
		goTo({ name: "merchant-no-permission" });
	}
}

export function validateUserHasAccessToPages(pages) {
	for (let i = 0; i < pages.length; i++) {
		if (isUserHasAccess(pages[i])) {
			return true;
		}
	}

	goTo({ name: "merchant-no-permission" });
}

export function isNumber(value) {
	if (!valueExist(value)) {
		return false;
	} else if (value[0] >= "0" && value[0] <= "9") {
		return true;
	}
	return false;
}

export function getCommonData() {
	return CommonData.data;
}

export function getData(key) {
	return CommonData.getData(key);
}

export function addData(key, value) {
	CommonData.addData(key, value);
}

export function removeData(key) {
	CommonData.removeData(key);
}

export function showInfoToast(message) {
	toaster.info("<h4 class='text-white mb-0'><i class='fa-regular fa-check-circle text-white fa-lg me-3'></i>" + translate(message) + "</h4>");
}

export function showSuccessToast(message) {
	toaster.success("<h4 class='text-white mb-0'><i class='fa-regular fa-check-circle text-white fa-lg me-3'></i>" + translate(message) + "</h4>");
}

export function showErrorToast(message) {
	toaster.error("<h4 class='text-white mb-0'><i class='fa-regular fa-check-circlek-circle text-white fa-lg me-3'></i>" + translate(message) + "</h4>");
}

export function getQuarterOfHour(date: any = null) {
	let dateMoment = valueExist(date) ? moment(date) : moment();
	dateMoment = dateMoment.seconds(0);
	const minutes = dateMoment.minutes();
	if (minutes >= 0 && minutes < 15) {
		dateMoment = dateMoment.minutes(0);
	} else if (minutes >= 15 && minutes < 30) {
		dateMoment = dateMoment.minutes(15);
	} else if (minutes >= 30 && minutes < 45) {
		dateMoment = dateMoment.minutes(30);
	} else if (minutes >= 45) {
		dateMoment = dateMoment.minutes(45);
	}

	return dateMoment;
}

export function compareDates(moment1, moment2) {
	const moment1Format = formatDateAndTime(moment1);
	const moment2Format = formatDateAndTime(moment2);

	if (moment1Format > moment2Format) {
		return 1;
	} else if (moment1Format == moment2Format) {
		return 0;
	}

	return -1;
}

export function initAppointmentsEvents(calendarApi, getAppointments) {
	$(document).on(
		{
			mouseenter: function () {
				const cellWidth = $("th.fc-col-header-cell").outerWidth();
				const cellHeight = $(this).outerHeight();
				const columnCount = $("thead table.fc-col-header th.fc-col-header-cell").children().length;
				const date = formatDate(moment(calendarApi.value.getDate()));

				if (!$(this).html()) {
					for (let i = 0; i < columnCount; i++) {
						$(this).append('<td class="temp-cell" style="border:0px; height:' + (cellHeight - 1) + "px;width:" + cellWidth + 'px"></td>');
					}
				}
				$(this)
					.children("td")
					.each((tdCellIndex, tdCell) => {
						$(tdCell).hover(
							() => {
								const time = $(tdCell).parent().data("time");
								const cellPreventClass = compareDates(moment(date + " " + time), getQuarterOfHour()) == -1 ? " full-calendar-prevent-click" : "";

								$(tdCell).html('<div class="full-calendar-hover-time' + cellPreventClass + '">' + formatTime(time, "hh:mm A") + "</div>");
							},
							() => {
								$(tdCell).html("");
							}
						);
					});
			},

			mouseleave: function () {
				$(this).children(".temp-cell").remove();
			},
		},
		"td.fc-timegrid-slot.fc-timegrid-slot-lane"
	);

	$(document).on(
		{
			mouseenter: function () {
				const instance = Popover.getInstance($(this));
				if (instance != null) {
					instance._config.content = $(this).find(".event-popover-content").eq(0).html();
					instance.show();
				}
			},
			mouseleave: function () {
				const instance = Popover.getInstance($(this));
				if (instance != null) {
					Popover.getInstance($(this)).hide();
				}
			},
			click: function () {
				const instance = Popover.getInstance($(this));
				if (instance != null) {
					Popover.getInstance($(this)).hide();
				}
			},
		},
		".full-calendar-custom-event"
	);

	$(document).on("global_appointments_changed", () => {
		getAppointments();
	});
}

export function destroyAppointmentsEvents() {
	$(document).off("mouseenter mouseleave", "td.fc-timegrid-slot.fc-timegrid-slot-lane");
	$(document).off("mouseenter mouseleave click", ".full-calendar-custom-event");
	$(document).off("global_appointments_changed");
}

export function refertchGlobalInformations() {
	store.dispatch(Actions.GET_GLOBAL_INFORMATIONS);
}

export function clearDataUtil() {
	keenthemes.clearDataUtil();
}

let globalChangePasswordModal = null as Modal;
export function showGlobalChangePasswordModal() {
	globalChangePasswordModal.show();
}
export function setGlobalChangePasswordModal(modal) {
	globalChangePasswordModal = modal;
}

export function getRequiredMessage(name) {
	return translate(name) + " " + translate("is_required");
}

export function convertTimeToArabic(time) {
	return time.replace("AM", translate("am")).replace("PM", translate("pm"));
}

export function toggleNumber(number) {
	if (number == 0) {
		return 1;
	} else if (number == 1) {
		return 0;
	}

	return null;
}

export const wizardSteps = [
	{ name: "general-informations", progress: 20, link: "merchant-wizard", text: "business_setup_guide" },
	{ name: "vat", progress: 10, link: "merchant-wizard", text: "business_setup_guide" },
	{ name: "business-hours", progress: 15, link: "merchant-wizard", text: "business_setup_guide" },
	{ name: "employees", progress: 20, link: "merchant-wizard", text: "business_setup_guide" },
	{ name: "invoice-setting", progress: 15, link: "merchant-wizard", text: "business_setup_guide" },
	{ name: "activate-services", progress: 20, link: "merchant-menu-services-activate-services", text: "setup_menu_now" },
];

export function updateWizardProgress(step) {
	if (valueExist(getAccountData()["wizard_progress"])) {
		const doneWizardSteps = JSON.parse(getAccountData()["wizard_progress"]);
		if (doneWizardSteps.includes(step)) {
			return false;
		}
	}

	const jsonData = new JsonData();
	jsonData.append("step", step);

	axios
		.post("wizard/update_progress", jsonData.getData())
		.then(() => {
			store.dispatch(Actions.GET_GLOBAL_INFORMATIONS);
		})
		.catch((error) => {
			showRequestError(error);
		});
}

export function getWizardValue(accountWizardProgress = null) {
	let wizardProgress = null as any;
	if (valueExist(accountWizardProgress)) {
		wizardProgress = accountWizardProgress;
	} else if (valueExist(getAccountData()["wizard_progress"])) {
		wizardProgress = getAccountData()["wizard_progress"];
	}

	if (!valueExist(wizardProgress)) {
		return 0;
	}

	let doneWizardProgress = 0;
	const doneWizardSteps = JSON.parse(wizardProgress);
	for (let i = 0; i < doneWizardSteps.length; i++) {
		for (let j = 0; j < wizardSteps.length; j++) {
			if (doneWizardSteps[i] == wizardSteps[j].name) {
				doneWizardProgress += wizardSteps[j].progress;
				break;
			}
		}
	}

	return doneWizardProgress;
}

export function getMobileValidationLength(value) {
	let length = 3 as number;

	let plusBaseNumber = false;
	let doubleZeroBaseNumber = false;
	let countryCode = "";
	let zeroBaseNumber = false;

	if (value.startsWith("+")) {
		length += 1;
		plusBaseNumber = true;
	} else if (value.startsWith("00")) {
		length += 2;
		doubleZeroBaseNumber = true;
	}

	if (plusBaseNumber) {
		countryCode = value.substring(1, 4);

		if (value.substring(4, 5) == "0") {
			zeroBaseNumber = true;
		}
	} else if (doubleZeroBaseNumber) {
		countryCode = value.substring(2, 5);

		if (value.substring(5, 6) == "0") {
			zeroBaseNumber = true;
		}
	} else {
		countryCode = value.substring(0, 3);

		if (value.substring(3, 4) == "0") {
			zeroBaseNumber = true;
		}
	}

	if (countryCode == "966" || countryCode == "971" || countryCode == "962" || countryCode == "970" || countryCode == "972") {
		length += 9;
	} else if (countryCode == "965" || countryCode == "974" || countryCode == "973" || countryCode == "968") {
		length += 8;
	}

	if ((countryCode == "966" || countryCode == "971" || countryCode == "962" || countryCode == "970" || countryCode == "972") && zeroBaseNumber) {
		length += 1;
	}

	return length;
}

export function getMobileValue(value) {
	if (!valueExist(value)) {
		return value;
	}

	let newValue = value;

	if (newValue.startsWith("+")) {
		newValue = newValue.substring(1, value.length);
	} else if (newValue.startsWith("00")) {
		newValue = newValue.substring(2, value.length);
	}

	let countryCode = "";
	let zeroBaseNumber = false;

	countryCode = newValue.substring(0, 3);

	if (
		countryCode == "966" ||
		countryCode == "971" ||
		countryCode == "962" ||
		countryCode == "970" ||
		countryCode == "972" ||
		countryCode == "965" ||
		countryCode == "974" ||
		countryCode == "973" ||
		countryCode == "968"
	) {
		if (newValue.substring(3, 4) == "0") {
			zeroBaseNumber = true;
		}

		if ((countryCode == "966" || countryCode == "971" || countryCode == "962" || countryCode == "970" || countryCode == "972") && zeroBaseNumber) {
			newValue = newValue.substring(0, 3) + newValue.substring(4, newValue.length);
		}
	}

	return newValue;
}

export function jquery(selector) {
	return $(selector);
}

export function iniateVenobox(selector) {
	new venobox({
		selector: selector,
	});
}

export function isIphone() {
	if (navigator.userAgent.toLowerCase().search("iphone") > -1) {
		return true;
	}
	return false;
}

export function isAndroid() {
	if (navigator.userAgent.toLowerCase().search("android") > -1) {
		return true;
	}
	return false;
}

export function isPc() {
	if (!isIphone() && !isAndroid()) {
		return true;
	}
	return false;
}

export function getScreenSize() {
	if (window.screen.width < 576) {
		return "xs";
	} else if (window.screen.width < 768) {
		return "sm";
	} else if (window.screen.width < 992) {
		return "md";
	} else if (window.screen.width < 1200) {
		return "lg";
	} else if (window.screen.width < 1400) {
		return "xl";
	}
	return "xxl";
}

export function isMobileScreen() {
	const screenSize = getScreenSize();
	return screenSize == "xs" || screenSize == "sm";
}

export function copyTextToClipboard(value, showToast) {
	const tmpTextField = document.createElement("textarea");
	tmpTextField.textContent = value;
	tmpTextField.setAttribute("style", "position:absolute; right: 200%;");
	document.body.appendChild(tmpTextField);
	tmpTextField.select();
	tmpTextField.setSelectionRange(0, 99999);
	document.execCommand("copy");
	tmpTextField.remove();

	if (showToast) {
		showSuccessToast("copied_to_clipboard");
	}
}

export function rgbaToHex(rgba) {
	const outParts = [
		rgba["r"].toString(16),
		rgba["g"].toString(16),
		rgba["b"].toString(16),
		Math.round(rgba["a"] * 255)
			.toString(16)
			.substring(0, 2),
	];

	outParts.forEach(function (part, i) {
		if (part.length === 1) {
			outParts[i] = "0" + part;
		}
	});

	return "#" + outParts.join("");
}

export function handleEmployeesAvailableTimes(employees, date, appointmentServices, serviceJobTime) {
	const currentDateAndTime = getCurrentTimestamp();

	for (let i = 0; i < employees.length; i++) {
		const newAvailableTimes = [] as Array<any>;

		for (let j = 0; j < employees[i].available_times.length; j++) {
			let timeValid = true;

			if (date + " " + employees[i].available_times[j] < currentDateAndTime) {
				timeValid = false;
			}

			if (timeValid) {
				const serviceEndTime = formatTime(moment(date + " " + employees[i].available_times[j]).add(serviceJobTime, "minutes"));

				for (let k = 0; k < appointmentServices.length; k++) {
					if (isAppointmentsConflict(employees[i].available_times[j], serviceEndTime, appointmentServices[k].from, appointmentServices[k].to)) {
						timeValid = false;
						break;
					}
				}
			}

			if (timeValid) {
				newAvailableTimes.push(employees[i].available_times[j]);
			}
		}

		employees[i].available_times = newAvailableTimes;
	}
}

export function hexToRgba(hex) {
	const r = parseInt(hex.substring(1, 3), 16),
		g = parseInt(hex.substring(3, 5), 16),
		b = parseInt(hex.substring(5, 7), 16),
		a = parseInt(hex.substring(7, 9), 16) / 255;

	return { r: r, g: g, b: b, a: a };
}

export function hexToRgbaString(hex) {
	const r = parseInt(hex.substring(1, 3), 16),
		g = parseInt(hex.substring(3, 5), 16),
		b = parseInt(hex.substring(5, 7), 16),
		a = parseInt(hex.substring(7, 9), 16) / 255;

	return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function hexaToHex(hexa) {
	if (!valueExist(hexa)) {
		return null;
	}

	if (hexa.length < 9) {
		return hexa;
	}

	return hexa.substring(0, 7);
}

export function getEmployeeShortName(name) {
	if (!valueExist(name)) {
		return "";
	} else if (name.indexOf(" ") == -1) {
		return name;
	}

	return name.substring(0, name.indexOf(" "));
}

export function comma() {
	return getValueByCurrentLanguage(",", "،");
}

export function questionMark() {
	return getValueByCurrentLanguage("?", "؟");
}

export function getNotifications(type) {
	if (type == "stocks" && !isUserHasAccess(5, 5)) {
		return false;
	} else if (type == "surveys" && !isUserHasAccess(6, 38)) {
		return false;
	} else if (type == "suggestionsAndComplains" && !isUserHasAccess(6, 39)) {
		return false;
	} else if (type == "orders" && getAccountData()["hold_invoice"] == 0) {
		return false;
	}

	axios
		.get("notifications/get_records", {
			params: {
				date: getDateFromNow(),
				dateAndTime: getCurrentTimestamp(),
				type: type,
			},
		})
		.then((response) => {
			store.commit(Mutations.SET_NOTIFICATIONS_MENU_MUTATION, response.data);
		})
		.catch((error) => {
			showRequestError(error);
		});
}

export function markNotificationAsRead(type) {
	if (type == "stocks" && !isUserHasAccess(5, 5)) {
		return false;
	} else if (type == "surveys" && !isUserHasAccess(6, 38)) {
		return false;
	} else if (type == "suggestionsAndComplains" && !isUserHasAccess(6, 39)) {
		return false;
	} else if (type == "orders" && getAccountData()["hold_invoice"] == 0) {
		return false;
	}

	const jsonData = new JsonData();
	jsonData.append("type", type);

	axios
		.post("notifications/mark_as_read", jsonData.getData())
		.then(() => {
			getNotifications(type);
		})
		.catch((error) => {
			showRequestError(error);
		});
}

export function fromNow(dateAndTime) {
	return moment(dateAndTime).locale(getCurrentLanguage()).fromNow();
}

export function refreshPage() {
	window.location.reload();
	window.location.replace(window.location.href);
}

export function toggleValues(value, value1, value2) {
	if (value == value1) {
		return value2;
	}

	return value1;
}

export function getTrainingAccountId() {
	return process.env.VUE_APP_TRAINING_ACCOUNT_ID;
}

export function numberToColumnLetter(number) {
	let column = "";
	while (number > 0) {
		const remainder = (number - 1) % 26;
		column = String.fromCharCode(65 + remainder) + column;
		number = Math.floor((number - 1) / 26);
	}
	return column;
}

export function showUploadRequestError(error, fieldName, allowedExtensions) {
	if (
		error != null &&
		error.response != null &&
		error.response.status == 422 &&
		valueExist(error.response.data, false) &&
		valueExist(error.response.data.errors, false) &&
		valueExist(error.response.data.errors[fieldName], false)
	) {
		for (let i = 0; i < error.response.data.errors[fieldName].length; i++) {
			if (
				error.response.data.errors[fieldName][i].includes("must be an image") ||
				error.response.data.errors[fieldName][i].includes("be a file of type:")
			) {
				showErrorDialog(translate("uploaded_file_must_be_one_of_these_extensions") + " (" + allowedExtensions + ")");
				return false;
			}
		}
	}

	showRequestError(error);
}

export function findInSet(value, values) {
	if (!valueExist(value)) {
		return false;
	}

	return ("," + values.toLowerCase() + ",").includes("," + value.toLowerCase() + ",");
}

export function whatsappPackageConnectedAndActive() {
	return getAccountData()["account_whatsapp_package_status"] == 1 && getAccountData()["account_whatsapp_package_connected"] == 1;
}

export function getDayNameFromDate(date) {
	return moment(date).format("dddd");
}

export function convertDayNameToArabic(dayName) {
	if (dayName == "Saturday") {
		return "السبت";
	} else if (dayName == "Sunday") {
		return "الأحد";
	} else if (dayName == "Monday") {
		return "الإثنين";
	} else if (dayName == "Tuesday") {
		return "الثلاثاء";
	} else if (dayName == "Wednesday") {
		return "الأربعاء";
	} else if (dayName == "Thursday") {
		return "الخميس";
	} else if (dayName == "Friday") {
		return "الجمعة";
	}

	return "";
}

export function convertMonthShortToArabic(month) {
	if (!valueExist(month)) {
		return null;
	}

	const monthsInEnglish = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const monthsInArabic = ["شهر 1", "شهر 2", "شهر 3", "شهر 4", "شهر 5", "شهر 6", "شهر 7", "شهر 8", "شهر 9", "شهر 10", "شهر 11", "شهر 12"];

	return monthsInArabic[monthsInEnglish.indexOf(month)];
}

export function getUmtSigniture() {
	return safeValue(process.env.VUE_APP_UMT_SIGNITURE, "");
}

export function getUmtEnglishName() {
	return safeValue(process.env.VUE_APP_UMT_ENGLISH_NAME, "");
}

export function getUmtArabicName() {
	return safeValue(process.env.VUE_APP_UMT_ARABIC_NAME, "");
}

export function getUmtNameBasedOnLanguage() {
	return getValueByCurrentLanguage(getUmtEnglishName(), getUmtArabicName());
}

export function getUmtUrl() {
	return safeValue(process.env.VUE_APP_UMT_URL, "");
}

export function getSalonOnlineUrl(aliasName, language) {
	return resolveUrlHref({ name: "online-home", params: { language: language, aliasName: aliasName } });
}

export function getAppUrl(url) {
	if (url == undefined || url == null) {
		return null;
	}

	return process.env.VUE_APP_URL + "/" + url;
}

export function isManager() {
	return (
		window.location.pathname.startsWith("/manager") ||
		window.location.pathname.startsWith("/en/manager") ||
		window.location.pathname.startsWith("/ar/manager")
	);
}

export function prepareSvgId(symbol) {
	if (!valueExist(symbol)) {
		return symbol;
	}

	return symbol.toLowerCase();
}

export function getDeviceType(userAgent) {
	if (userAgent == null) {
		return null;
	}

	const userAgentLoweredCase = userAgent.toLowerCase();

	if (/iphone/.test(userAgentLoweredCase)) {
		return "IPhone";
	} else if (/ipad/.test(userAgentLoweredCase)) {
		return "IPad";
	} else if (/ipod/.test(userAgentLoweredCase)) {
		return "IPod";
	} else if (/android/.test(userAgentLoweredCase)) {
		return "Android";
	} else if (/windows phone/.test(userAgentLoweredCase)) {
		return "Windows Phone";
	} else {
		return "Desktop";
	}
}

export function getBrowserType(userAgent) {
	if (userAgent == null) {
		return null;
	}

	let browserType = "";

	if (userAgent.indexOf("Chrome") !== -1 && userAgent.indexOf("Edg") === -1) {
		browserType = "Google Chrome";
	} else if (userAgent.indexOf("Firefox") !== -1) {
		browserType = "Mozilla Firefox";
	} else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1) {
		browserType = "Apple Safari";
	} else if (userAgent.indexOf("Edg") !== -1) {
		browserType = "Microsoft Edge";
	} else if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf("OPR") !== -1) {
		browserType = "Opera";
	} else if (userAgent.indexOf("Trident") !== -1 && userAgent.indexOf("MSIE") !== -1) {
		browserType = "Internet Explorer";
	} else {
		browserType = "Unknown";
	}

	return browserType;
}

export function setUserLastLanguage(type, id, force, language) {
	const key = getUmtSigniture() + "_" + type + "_" + id;

	const oldValue = window.localStorage.getItem(key);

	if (!valueExist(oldValue) || force) {
		window.localStorage.setItem(key, language);
	}
}

export function getUserLastLanguage(type, id) {
	const key = getUmtSigniture() + "_" + type + "_" + id;

	return window.localStorage.getItem(key);
}

export function calculateDistance(latitude1, longitude1, latitude2, longitude2) {
	const earthRadius = 6371000; // Radius of the Earth in meters

	const dLat = toRadians(latitude2 - latitude1);
	const dLon = toRadians(longitude2 - longitude1);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(latitude1)) * Math.cos(toRadians(latitude2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const distance = earthRadius * c;

	return distance;
}

export function toRadians(degrees) {
	return degrees * (Math.PI / 180);
}

export function decodeHtmlEntities(encodedHtml) {
	if (!valueExist(encodedHtml)) {
		return null;
	}

	const textarea = document.createElement("textarea");
	textarea.innerHTML = encodedHtml;
	return textarea.value;
}

export function numberToExcelColumn(number) {
	let result = "";

	while (number > 0) {
		const remainder = (number - 1) % 26;
		result = String.fromCharCode(65 + remainder) + result;
		number = Math.floor((number - 1) / 26);
	}

	return result;
}
